<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <all-log :dialogLog="dialogLog" />
    <el-card>
        <div class="menu-box">
            <h2>财务执行列表</h2>
            <div class="box-bd">
                <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
        <el-tooltip
          class="item"
          effect="dark"
          content="查看日志"
          placement="top-start"
        >
          <el-button type="warning" @click="dialogOpen">查看日志</el-button>
        </el-tooltip>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
        <el-table-column
          align="center"
          prop="id"
          label="列表ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="u_id"
          label="发起人ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="add_time"
          label="发起时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="reason"
          label="申请事由"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="type_id"
          label="申购类型ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="delivery_time"
          label="期望使用时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="pay_id"
          label="付款方式ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
        ></el-table-column>
        <el-table-column
          align="center"
          label="产品图"
        >
        <template slot-scope="scope">
            <img :src="scope.row.img[0]" style="width:50px;height:50px;" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
               <el-tooltip class="item" effect="dark"  content="查看申购单详情" placement="top-start">
            <el-button icon="el-icon-edit" size="mini" type="warning" @click="lookPurchase(scope.row.id)" >编辑</el-button>
          </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        :pager-count="15"
        background
        :total="exp.num"
      >
      </el-pagination>
            </div>
        </div> 
      
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { PostListPurchase } from "@/api/mysubscribe/mySubscribe";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //查看日志模态框
      dialogLog: {
        state: false,
      },
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [
        {
          id: 1,
          u_id: 1,
          add_time: "2022-02-17",
          reason: "申请事由",
          type_id: 4,
          delivery_time: "2022-02-17",
          pay_id: 4,
          remark: "测试备注",
          img: ["https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLuXjjic72ugLhpYkicq8XoTDtnk4ozicmViclW3sCuD2ZT69e9vgo7P46BpRccEJc83LWaCVUMzhuWvA/132"],
          state: 0,
          a_section: 1,
          b_section: 1,
          c_section: 1,
          d_section: null,
          type_name: "办公用品",
          pay_name: "办公用品",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
      lookPurchase(id){
       console.log("查看申购单详情",id)
      },
    //查看日志
    dialogOpen() {
      this.dialogLog.state = true;
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      // this.PostLevelList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      // this.PostLevelList(data);
    },

    /**-----------------网络请求生命周期函数--------------------- */
    async PostListPurchase(data) {
      let res = await PostListPurchase(data);
      console.log(res);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data = {
      page: this.getInfo.page_code,
      list_num: this.getInfo.page_num,
      status: 0,
    };
    // this.PostListPurchase(data);
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
</style>